<template>
    <van-notice-bar left-icon="volume-o" :scrollable="false" mode="closeable" v-if="noticeList.length > 0">
        <van-swipe
            vertical
            class="notice-swipe"
            :autoplay="5000"
            :show-indicators="false"
        >
            <van-swipe-item @click="handleShowNotice(notice)" v-for="notice in noticeList" :key="notice.id">{{ notice.title }}</van-swipe-item>
        </van-swipe>
    </van-notice-bar>
</template>

<script>
import { Dialog } from 'vant'
import { getBulletinList } from '@/api/common'
export default {
    name: "Bulletin",
    data() {
        return {
            noticeList: []
        }
    },
    methods: {
        handleShowNotice (notice) {
            Dialog.alert({
                messageAlign: 'left',
                title: notice.title,
                message: notice.content,
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonText: '关闭'
            }).then(() => {
                console.log('close')
            })
        }
    },
    created() {
        getBulletinList().then(res => {
            this.noticeList = res.data
        })
    }
}
</script>

<style scoped>
.notice-swipe {
    height: 40px;
    line-height: 40px;
}
</style>
