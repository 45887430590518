<template>
    <desktop-live v-if="this.isPc" />
    <mobile-live v-else />
</template>

<script>
import MobileLive from './MobileLive'
import DesktopLive from './DesktopLive'
import { FINGER_PRINT, LOGIN_USER } from '@/utils/constants'

export default {
    name: 'LiveIndex',
    components: { MobileLive, DesktopLive },
    inject: ['isPc'],
    data () {
        return {
            sessionId: null,
            sessionKey: null,
            user: null
        }
    },
    created() {
        this.user = this.$ls.get(LOGIN_USER)
        let fingerprint = this.$ls.get(FINGER_PRINT)
        if (!fingerprint) {
            this.$ls.set(FINGER_PRINT, Math.random().toString(36).substr(2))
        }
        //子组件会用到这两个参数，主要是为了统计
        this.sessionId = fingerprint
        this.sessionKey = Math.random().toString(36).substr(2)
    }
}
</script>
