<template>
    <div>
        <header class="header">
            <div class="container header-list">
                <img src="/favicon.ico" alt="">
                <p>{{ description }}</p>
            </div>
        </header>
        <div style="height: 52px"></div>
        <div class="container content">
            <van-row gutter="16">
                <van-col span="18">
                    <middle-loading :loading="loading" v-if="loading" />

                    <div v-else>
                        <van-empty v-if="notFound" description="直播不存在" />
                        <van-empty v-else-if="serveError" description="服务器错误">
                            <van-button round type="info" class="bottom-button" @click="initLiver">点我刷新</van-button>
                        </van-empty>
                        <div v-else>
                            <div id="xg" ref="xg"></div>
                            <div class="login-panel" v-if="user === null">
                                <p class="login-tip">登录后即可使用更多功能</p>
                                <wechat-login />
                            </div>

                            <template v-else>
                                <div class="note-c">
                                    <vue-lazy-component>
                                        <note :live="live"></note>
                                        <van-loading style="text-align: center" type="spinner" size="16px" slot="skeleton" />
                                    </vue-lazy-component>
                                </div>
                                <div class="chat-c">
                                    <vue-lazy-component>
                                        <chat :live="live" :user="user"></chat>
                                        <van-loading style="text-align: center" type="spinner" size="16px" slot="skeleton" />
                                    </vue-lazy-component>
                                </div>
                            </template>
                        </div>
                    </div>
                </van-col>
                <van-col span="6">
                    <van-panel title="直播概览" class="widget-panel live-panel">
                        <count-down :live="live" />
                    </van-panel>
                    <van-panel title="圣经" class="widget-panel bible-panel">
                        <bible />
                    </van-panel>
                    <van-panel title="赞美诗" class="widget-panel poem-panel">
                        <poem />
                    </van-panel>
                    <van-panel v-if="!loading" title="往期回顾" class="widget-panel playback-panel">
                        <playback @playback="handleChangeVideo" :channel-id="live.channel.id" />
                    </van-panel>
                </van-col>
            </van-row>
        </div>
    </div>
</template>

<script>
import Playback from './widgets/Playback'
import Note from './widgets/Note'
import Chat from './widgets/Chat'
import CountDown from './widgets/CountDown'
import Poem from '@/components/Poem'
import Bible from '@/components/Bible'
import 'xgplayer'
import HlsJsPlayer from 'xgplayer-hls.js'
import { getLive, reportTimelines } from '@/api/live'
import { isAndroid, isWeixinBrowser } from '@/utils/util'
import Player from 'xgplayer'
import 'xgplayer-mp4'
import { FINGER_PRINT } from '@/utils/constants'
import { Toast } from 'vant'
import { description } from '@/../package.json'
import { LOGIN_USER } from '@/utils/constants'
import WechatLogin from '@/components/WechatLogin'
import MiddleLoading from '@/components/MiddleLoading'

export default {
    name: 'DesktopLive',
    components: { Poem, Bible, Playback, CountDown, Chat, Note, WechatLogin, MiddleLoading },
    metaInfo () {
        return {
            title: this.live.title,
            titleTemplate: '%s - ' + (this.live.channel ? this.live.channel.name : '')
        }
    },
    data () {
        return {
            isPlayback: false,
            serveError: false,
            notFound: false,
            loading: true,
            player: null,
            live: {
                id: 0,
                title: '在线直播',
                author: '',
                channel: {
                    id: 0
                }
            },
            ace: {},
            user: null
        }
    },
    methods: {
        handleChangeVideo (ace) {
            if (this.live.id === ace.id) {
                return
            }
            Toast.loading({
                message: '加载中...',
                duration: 0,
                forbidClick: true
            })
            this.player && this.player.once('canplay', () => {
                Toast.clear()
            })
            this.live = ace
            if (!this.isPlayback) {
                this.player && this.player.destroy()
                this.player = new Player(Object.assign(this.playerOptions, {
                    url: ace.media.original_url
                }))
                this.player.on('canplay', () => {
                    Toast.clear()
                })
            } else {
                if (this.player.hasStart) {
                    this.player.pause()
                    setTimeout(() => {
                        this.player.src = ace.media.url
                        Toast.clear()
                    }, 50)
                } else {
                    this.player.start(ace.media.url)
                    Toast.clear()
                }
            }
        },
        initLiver (params = {}) {
            this.loading = true
            params.code = this.$route.params.code
            params.fingerprint = this.$ls.get(FINGER_PRINT)
            getLive(params).then((res) => {
                this.live = res.data
                this.loading = this.serveError = this.notFound = false
                this.$nextTick(() => {
                    if (this.live.media) {
                        this.isPlayback = true
                        this.player = new Player(Object.assign(this.playerOptions, {
                            url: this.live.media.original_url
                        }))
                    } else {
                        this.player = new HlsJsPlayer(Object.assign(this.playerOptions, {
                            url: this.live.meta.pull_stream_url
                        }))
                    }
                    this.player.once('ready', () => {
                        this.$eventBus.$emit('playerReady')
                    })
                    this.player.once('play', () => {
                        this.handlePlay()
                    })
                })
            }).catch((e) => {
                const resp = e.response
                if (resp) {
                    if (resp.status === 404) {
                        this.notFound = true
                    }
                    if (resp.status !== 200) {
                        this.serveError = true
                    }
                }
            }).finally(() => {
                this.loading = false
            })
        },
        handlePlay () {
            if (this.isPlayback) {
                return
            }
            setInterval(() => {
                const duration = parseInt(this.player.currentTime)
                if (duration <= 0) {
                    return;
                }
                if (!this.$parent.$data.sessionId) {
                    return;
                }

                reportTimelines({
                    'live_id': this.live.id,
                    'session_id': this.$parent.$data.sessionId,
                    'session_key': this.$parent.$data.sessionKey,
                    'duration': duration
                })
            }, 1000 * 30)
        }
    },
    computed: {
        description () {
            if (this.live.channel) {
                return this.live.channel.name
            }
            return description
        },
        playerOptions () {
            return {
                id: 'xg',
                url: '',
                fluid: true,
                poster: this.live.poster,
                ignores: ['volume'],
                playsinline: this.playsinline,
                noLog: true,
                autoplay: true,
                airplay: true,
                closeVideoClick: false,
                closeVideoTouch: false,
                'x5-video-player-type': 'h5-page',
                'x5-video-player-fullscreen': true,
                preloadTime: 10,
                errorTips: `聚会尚未开始, 或请<span>刷新</span>试试`
            }
        },
        playsinline () {
            if (!isAndroid()) {
                return true
            }

            if (isWeixinBrowser()) {
                return false
            }
            return true
        }
    },
    mounted() {
        let networkType = 'unknown'
        if (typeof navigator.connection !== "undefined") {
            networkType = navigator.connection.effectiveType
        }
        this.initLiver({ network_type: networkType })
    },
    created () {
        this.user = this.$ls.get(LOGIN_USER)
        this.$eventBus.$on('login-success', ({ user }) => {
            this.user = user
        })
    },
};
</script>
<style lang="less">
    #xg {

    }
    .xgplayer-skin-default .xgplayer-enter .xgplayer-enter-spinner {
        width: 20px !important;
        height: 20px !important;
    }
    .header {
        height: 52px;
        position: fixed;
        top: 0;
        box-shadow: 0 1px 3px rgba(26, 26, 26, .1);
        width: 100%;
        background: #fff;
        z-index: 2001;

        .header-list {
            display: flex;
            align-items: center;
            height: 100%;

            p {
                margin: 0px;
                margin-left: 10px;
                color: #323233;
            }
            img {
                width: 36px;
                height: 36px;
            }
        }
    }
    .container {
        width: 100%;
        margin-right: auto;
        margin-left: auto;
    }

    @media (min-width: 576px) {
        .container {
            max-width: 540px;
        }
    }

    @media (min-width: 768px) {
        .container {
            max-width: 720px;
        }
    }

    @media (min-width: 992px) {
        .container {
            max-width: 960px;
        }
    }

    @media (min-width: 1200px) {
        .container {
            max-width: 1140px;
        }
    }

    @media (min-width: 1400px) {
        .container {
            max-width: 1320px;
        }
    }

    .content {
        margin-top: 16px;
    }

    .widget-panel {
        margin-bottom: 16px;
        max-height: 450px;
        overflow-y: scroll;
        -ms-overflow-style: none;
        overflow: -moz-scrollbars-none;
        box-shadow: 0 1px 3px rgba(26, 26, 26, .1);
        border-radius: 4px;
        .van-panel__header {
            padding: 16px;
             span {
                 font-size: 18px;
             }
        }
        .van-overlay {
            display: none;
        }

        .poem-popup, .bible-popup, .reader-popup {
            box-shadow: 0 1px 3px rgba(26, 26, 26, .3);
            width: 32.5%;
            right: 0px;
            left: inherit;
            height: calc(100% - 52px);
            max-height: 100%;
        }
    }

    .widget-panel::-webkit-scrollbar {
        display: none;
        width: 0 !important;
    }

    .note-c, .chat-c {
        padding: 0px 16px 16px 16px;
        margin-top: 16px;
        background: #fff;
        box-shadow: 0 1px 3px rgba(26, 26, 26, .1);
        border-radius: 4px;
    }
    .chat-c {
        transform:translate(0, 0);
        .chat-content {
            max-height: 350px;
            overflow: scroll;
            -ms-overflow-style: none;
            overflow: -moz-scrollbars-none;
        }
        .chat-content::-webkit-scrollbar {
            display: none;
            width: 0 !important;
        }
    }
    .live-content {
        padding: 24px;
        font-size: 16px;
        line-height: 24px;

        p {
            margin: 0px;
        }
    }
    .login-panel {
        text-align: center;
        -ms-overflow-style: none;
        overflow: -moz-scrollbars-none;
        box-shadow: 0 1px 3px rgba(26, 26, 26, 0.1);
        border-radius: 4px;
        background: #fff;
        padding: 16px;
        margin-top: 16px;
    }
</style>
