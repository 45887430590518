<template>
    <middle-loading :loading="loading" v-if="loading" />
    <div v-else>
        <van-empty v-if="notFound" description="直播不存在" />
        <van-empty v-else-if="serveError" description="服务器错误">
            <van-button round type="info" class="bottom-button" @click="initLiver">点我刷新</van-button>
        </van-empty>
        <div v-else>
            <van-sticky>
                <div id="xg" ref="xg"></div>
                <count-down :status.sync="showCountDown" :live="live" />
            </van-sticky>
            <van-tabs v-model="active" color="#1989fa" sticky :offset-top="showCountDown ? offsetTop + 43 : offsetTop">
                <van-tab title="聊天" v-if="live.meta.open_widgets.indexOf('chat') !== -1">
                    <bulletin />
                    <chat ref="chat" :live="live" :user="user" />
                </van-tab>
                <van-tab title="代祷" v-if="live.meta.open_widgets.indexOf('intercession') !== -1">
                    <intercession ref="intercession" :live="live" />
                </van-tab>
                <van-tab title="概览" v-if="live.meta.open_widgets.indexOf('content') !== -1">
                    <van-empty description="暂无内容" v-if="!live.content" />
                    <div class="live-content" v-else>
                        <p v-html="live.content"></p>
                    </div>
                </van-tab>
                <van-tab title="圣经" v-if="live.meta.open_widgets.indexOf('bible') !== -1"><bible /></van-tab>
                <van-tab title="诗颂" v-if="live.meta.open_widgets.indexOf('poem') !== -1"><poem /></van-tab>
                <van-tab title="回放" v-if="live.meta.open_widgets.indexOf('playback') !== -1"><playback :channel-id="live.channel.id" /></van-tab>
            </van-tabs>

            <div class="chat-login" v-if="user.id === 0">
                <wechat-login :icon="true">
                    <template slot="text">登录参与聊天</template>
                </wechat-login>
            </div>
        </div>
    </div>
</template>

<script>
import Playback from '@/view/live/widgets/Playback'
import Intercession from '@/view/live/widgets/Intercession'
import CountDown from '@/view/live/widgets/CountDown'
import Poem from '@/components/Poem'
import Bible from '@/components/Bible'
import Chat from '@/view/live/widgets/Chat'
import Bulletin from '@/view/live/widgets/Bulletin'
import wechatMixin from '@/utils/mixin'
import 'xgplayer'
import HlsJsPlayer from 'xgplayer-hls.js'
import { getLive, reportTimelines } from '@/api/live'
import { isAndroid, isWeixinBrowser } from '@/utils/util'
import { FINGER_PRINT } from '@/utils/constants'
import 'xgplayer-mp4'
import MiddleLoading from '@/components/MiddleLoading'
import WechatLogin from '@/components/WechatLogin'
import wx from 'weixin-js-sdk'
import { LOGIN_USER } from '@/utils/constants'

export default {
    name: 'MobileLive',
    mixins: [wechatMixin],
    components: { Poem, Bible, Playback, CountDown, MiddleLoading, Chat, WechatLogin, Intercession, Bulletin },
    metaInfo () {
        return {
            title: this.live.title,
            titleTemplate: '%s - ' + (this.live.channel ? this.live.channel.name : '')
        }
    },
    data () {
        return {
            showCountDown: false,
            serveError: false,
            notFound: false,
            loading: true,
            active: 0,
            offsetTop: 0,
            player: null,
            live: {
                id: 0,
                title: '在线直播',
                author: '',
                meta: {
                    open_widgets: []
                }
            },
            user: {
                id: 0
            }
        }
    },
    methods: {
        initLiver (params = {}) {
            this.loading = true
            params.code = this.$route.params.code
            params.fingerprint = this.$ls.get(FINGER_PRINT)
            getLive(params).then((res) => {
                this.live = res.data
                this.loading = this.serveError = this.notFound = false
                this.$nextTick(() => {
                    this.player = new HlsJsPlayer(Object.assign(this.playerOptions, {
                        url: this.live.meta.pull_stream_url
                    }))

                    this.offsetTop = this.$refs.xg.offsetHeight
                    this.player.once('ready', () => {
                        this.$eventBus.$emit('playerReady')
                    })
                    this.player.once('play', () => {
                        this.handlePlay()
                    })
                })
                this.registerWechatShare(this.live)
            }).catch((e) => {
                const resp = e.response
                if (resp) {
                    if (resp.status === 404) {
                        this.notFound = true
                    }
                    if (resp.status !== 200) {
                        this.serveError = true
                    }
                }
            }).finally(() => {
                this.loading = false
            })
        },
        handlePlay () {
            setInterval(() => {
                const duration = parseInt(this.player.currentTime)
                if (duration <= 0) {
                    return;
                }
                if (!this.$parent.$data.sessionId) {
                    return;
                }

                reportTimelines({
                    'live_id': this.live.id,
                    'session_id': this.$parent.$data.sessionId,
                    'session_key': this.$parent.$data.sessionKey,
                    'duration': duration
                })
            }, 1000 * 30)
        }
    },
    computed: {
        playerOptions () {
            return {
                id: 'xg',
                url: '',
                fluid: true,
                poster: this.live.poster,
                ignores: ['volume'],
                playsinline: this.playsinline,
                noLog: true,
                autoplay: true,
                airplay: true,
                closeVideoClick: false,
                closeVideoTouch: false,
                'x5-video-player-type': 'h5-page',
                'x5-video-player-fullscreen': true,
                preloadTime: 10,
                errorTips: `聚会尚未开始, 或请<span>刷新</span>试试`
            }
        },
        playsinline () {
            if (!isAndroid()) {
                return true
            }

            if (isWeixinBrowser()) {
                return false
            }
            return true
        }
    },
    mounted() {
        if (!isWeixinBrowser()) {   //手机端浏览器
            let networkType = 'unknown'
            if (typeof navigator.connection !== "undefined") {
                networkType = navigator.connection.effectiveType
            }
            this.initLiver({ network_type: networkType })
            return
        }

        wx.ready(() => {
            wx.getNetworkType({
                success: (res) => {
                    let networkType = res.networkType ? res.networkType : 'unknown'
                    this.initLiver({ network_type: networkType })
                }
            })
        })
    },
    destroyed() {
        this.player && this.player.destroy()
    },
    created () {
        this.user = this.$ls.get(LOGIN_USER, { id: 0 })
    }
};
</script>
<style lang="less">
    .xgplayer-skin-default .xgplayer-enter .xgplayer-enter-spinner {
        width: 20px !important;
        height: 20px !important;
    }

    .live-content {
        padding: 24px;
        font-size: 16px;
        line-height: 24px;

        p {
            margin: 0px;
        }

        .online-badge {
            position: absolute;
            right: 24px;
            font-size: 14px;
            border: 1px solid #000;
            border-radius: 16px;
            padding: 0px 8px;
            background: #fff;
            opacity: .7;
        }
    }
    .chat-login {
        width: 100%;
        position: fixed;
        left: 0;
        bottom: 0;
        padding: 0px 24px 34px 24px;
        box-sizing: border-box;
    }

</style>
