<template>
    <div>
        <van-empty description="暂无内容" v-if="intercessions.length === 0" />
        <div style="padding-bottom: 80px" v-else>
            <van-cell v-for="inter in intercessions" :key="inter.id" :title="inter.intercessor" :label="inter.content" />
        </div>
        <div class="intercession-area">
            <van-button
                class="add-button"
                native-type="button"
                :round="true"
                :block="true"
                type="info"
                :loading="loading"
                :disabled="loading"
                @click="visible = true"
            >
            我要代祷
            </van-button>
        </div>
        <van-popup v-model="visible" round position="bottom" closeable>
            <div class="intercession-header">代祷信息</div>
            <van-form validate-first @submit="handleSubmit">
                <van-field
                    required
                    size="large"
                    v-model="form.intercessor"
                    name="代祷人"
                    label="代祷人"
                    placeholder="请输入代祷人"
                    :rules="[{ required: true, message: '请输入代祷人' }]"
                />
                <van-field
                    required
                    size="large"
                    v-model="form.content"
                    type="textarea"
                    maxlength="500"
                    row="5"
                    show-word-limit
                    label="代祷事项"
                    placeholder="请输入代祷事项"
                    :rules="[{ required: true, message: '请输入代祷事项' }]"
                    >
                </van-field>
                <van-field
                    size="large"
                    v-model="form.belongs_church"
                    center
                    label="所属堂点"
                    placeholder="请输入所属堂点"
                    >
                </van-field>
                <van-field
                    size="large"
                    v-model="form.contact_details"
                    center
                    label="联系方式"
                    placeholder="请输入联系方式"
                    >
                </van-field>
                <div style="margin: 16px;">
                    <van-button round block type="info" native-type="submit" :loading="loading">提交</van-button>
                </div>
            </van-form>
        </van-popup>
    </div>
</template>

<script>
import { getIntercessions, storeIntercession } from '@/api/live'
import { Dialog } from 'vant'
export default {
    name: 'Intercession',
    props: {
        live: {
            type: Object,
            require: true
        }
    },
    data () {
        return {
            visible: false,
            loading: false,
            keyword: null,
            intercessions: [],
            form: {
                intercessor: '',
                content: '',
                belongs_church: '',
                contact_details: '',
                channel_id: this.live.channel.id
            }
        }
    },
    methods: {
        handleSubmit () {
            Dialog.confirm({
                messageAlign: 'left',
                message: '提交代祷信息后需审核通过才可展示, 确定提交吗？',
                beforeClose: (action, done) => {
                    console.log(action)
                    if (action === 'confirm') {
                        storeIntercession(this.form).then((res) => {
                            this.form = {
                                intercessor: '',
                                content: '',
                                belongs_church: '',
                                contact_details: '',
                                channel_id: this.live.channel.id
                            }
                            this.$notify({ type: 'success', message: res.message })
                            this.visible = false
                        }).finally(() => {
                            done()
                        })
                    } else {
                        done()
                    }
                }
            })
        }
    },
    created() {
        this.loading = true
        getIntercessions({
            church_id: this.live.church.id,
            channel_id: this.live.channel.id
        }).then(res => {
            this.intercessions = res.data
        }).finally(() => {
            this.loading = false
        })
    }
}
</script>

<style lang="less">
   .intercession-area {
        width: 100%;
        position: fixed;
        left: 0;
        bottom: 0;
        padding: 0px 24px 34px 24px;
        box-sizing: border-box;
    }
    .add-button {
        box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)
    }
    .intercession-header {
        flex-shrink: 0;
        font-weight: 500;
        font-size: 18px;
        line-height: 48px;
        text-align: center;
    }
</style>

