<template>
    <div class="subscribe">
        <template v-if="subscribeStatus.push_subscribe">
            <van-button
                icon="success"
                native-type="button"
                size="mini"
                plain
                type="primary"
                :loading="sending"
                :disabled="sending"
                loading-size="16px"
                @click="showSubscribeActions = true"
            >已订阅</van-button>
            <subscribe-official-account ref="subscribeOfficialAccount" @attention-success="subscribeStatus.wechat_attention = true" overlay />
        </template>
        <van-button
            v-else
            native-type="button"
            @click="handleSubscribe"
            size="mini"
            plain
            type="info"
            :loading="sending"
            :disabled="sending"
            loading-size="16px"
        >+ 订阅</van-button>
        <van-action-sheet
            get-container="body"
            @select="handleSubscribeAction"
            v-model="showSubscribeActions"
            :actions="subscribeActions"
            cancel-text="取消"
            :round="false"
        />
    </div>
</template>

<script>
import { unsubscribe, subscribe, isSubscribed } from '@/api/live'
import SubscribeOfficialAccount from '@/components/SubscribeOfficialAccount'
import { LOGIN_USER } from '@/utils/constants'
import mixin from '@/utils/mixin'

export default {
    name: 'Subscribe',
    mixins: [mixin],
    props: {
        scene: {
            type: Number,
            require: true
        },
        live: {
            type: Object,
            require: true
        }
    },
    components: { SubscribeOfficialAccount },
    data () {
        return {
            sending: false,
            showSubscribeActions: false,
            subscribeStatus: {
                push_subscribe: false,
                wechat_attention: false
            }
        }
    },
    computed: {
        subscribeActions () {
            const actions = [
                { id: 'unsubscribe', name: '取消订阅', color: '#ee0a24', loading: this.sending }
            ]
            if (!this.subscribeStatus.wechat_attention) {
                actions.unshift({ id: 'subscribe_official_account', name: '关注公众号', subname: '关注公众号即可接收开播通知' })
            }
            return actions
        }
    },
    methods: {
        handleSubscribe () {
            if (!this.user) {
                this.$notify({ type: 'warning', message: '请先登录后再订阅' })
                return
            }
            this.sending = true
            subscribe({ scene: this.scene, channel_id: this.live.channel.id }).then(res => {
                this.$notify({ type: 'success', message: res.message })
                this.subscribeStatus = res.data
                this.$nextTick(() => {
                    if (!res.data.wechat_attention) {
                        this.$refs.subscribeOfficialAccount.show()
                    }
                })
            }).finally(() => {
                this.sending = false
            })
        },
        handleSubscribeAction (action) {
            if (action.id === 'subscribe_official_account') {  //关注公众号
                this.$refs.subscribeOfficialAccount.show()
                this.showSubscribeActions = false
            }
            if (action.id === 'unsubscribe') {  //取消订阅
                this.sending = true
                unsubscribe({ scene: this.scene, channel_id: this.live.channel.id }).then(res => {
                    this.$notify({ type: 'success', message: res.message })
                    this.subscribeStatus = res.data
                }).finally(() => {
                    this.sending = this.showSubscribeActions = false
                })
            }
        },
        handleLoginSuccess ({ user }) {
            this.user = user
            isSubscribed({ scene: this.scene, channel_id: this.live.channel.id }).then(res => {
                this.subscribeStatus = res.data
                this.sending = false
            })
        }
    },
    created() {
        this.sending = true
        this.user = this.$ls.get(LOGIN_USER)
        this.$eventBus.$on('login-success', this.handleLoginSuccess)
        if (!this.user) {
            this.sending = false
        } else {
            isSubscribed({ scene: this.scene, channel_id: this.live.channel.id }).then(res => {
                this.subscribeStatus = res.data
                this.sending = false
            })
        }
    }
}
</script>

<style lang="less">
    .subscribe {
        display: flex;
        justify-content: center;
        background: #fff;
    }
</style>
