<template>
    <div class="count-down">
        <div class="overview" v-if="showCountDown">
            <div class="count-down">
                <p>距开播：</p>
                <van-count-down :style="{ color: '#2d8cf0' }" :time="countDown" format="DD 天 HH 时 mm 分 ss 秒" @finish="countDownFinished = true" />
            </div>
            <subscribe :live="live" :scene="liveStartScene" v-if="showSubscribe" />
        </div>
    </div>
</template>

<script>
import Subscribe from './Subscribe'
import { LIVE_START_SCENE } from '@/utils/constants'

export default {
    name: 'CountDown',
    components: { Subscribe },
    props: {
        live: {
            type: Object,
            require: true
        }
    },
    data () {
        return {
            countDownFinished: false
        }
    },
    watch: {
        showCountDown: function (newQuestion) {
            this.$emit("update:status", newQuestion)
        }
    },
    computed: {
        showSubscribe () {
            return parseInt(process.env.VUE_APP_OPEN_SUBSCRIBE) === 1
        },
        showCountDown () {
            return !this.countDownFinished && this.countDown > 0
        },
        liveStartScene () {
            return LIVE_START_SCENE
        },
        countDown () {
            if (!this.live.live_at) {
                return 0
            }
            const liveAt = new Date(this.live.live_at.replace(/-/g, '/'))
            return liveAt.getTime() - new Date().getTime()
        }
    },
    created() {
        this.$emit("update:status", this.showCountDown)
    }
}
</script>

<style lang="less">
    .overview {
        background: linear-gradient(to right, #eaf2fb, #ffffff);
        font-weight: bold;
        padding: 10px 16px;
        text-align: left;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        p {
            margin: 0px;
            font-size: 16px;
            line-height: 24px;
        }

        .count-down {
            display: flex;

            .van-count-down {
                font-size: 16px;
                line-height: 24px;
            }
        }
    }

</style>
