<template>
    <div>
        <van-popup
            class="widget-popup"
            v-model="showAttention"
            position="bottom"
            :overlay="overlay"
            safe-area-inset-bottom
            close-on-popstate
            :style="{ height: actualHeight  }"
            @closed="handleClosed"
        >
            <div class="widget">
                <div class="popup-title">
                    <p>关注公众号</p>
                    <a href="" @click.prevent="showAttention = false">关闭</a>
                </div>
                <div class="subscribe-qrcode">
                    <my-image src="https://file4.mogody.com/avatars/qrcode_for_tjcjdtwx.jpg"></my-image>
                    <p class="add-tip">
                        <i></i>
                        <span>长按二维码或用微信扫一扫</span>
                        <i></i>
                    </p>
                    <p class="subscribe-tip">
                        <span>注：需关注公众号后才可接收通知</span>
                    </p>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
import { isSubscribedOfficialAccount } from '@/api/live'
import MyImage from '@/components/MyImage'
import mixin from '@/utils/mixin'

export default {
    name: "SubscribeOfficialAccount",
    components: { MyImage },
    mixins: [mixin],
    props: {
        tip: {
            type: String,
            require: false,
            default () {
                return '立即关注'
            }
        },
        overlay: {
            type: Boolean,
            require: false,
            default() {
                return false
            }
        },
        needCheck: {
            type: Boolean,
            require: false,
            default() {
                return true
            }
        }
    },
    data () {
        return {
            showAttention: false,
            timer: null,
            subscribeStatus: {
                wechat_attention: false
            }
        }
    },
    methods: {
        handleClosed () {
            if (this.timer) {
                clearInterval(this.timer)
            }
        },
        show () {
            this.showAttention = true
            if (!this.needCheck) {
                return false
            }
            this.timer = setInterval(this.timerCheckAttention, 2000)
        },
        timerCheckAttention () {
            isSubscribedOfficialAccount().then(res => {
                this.subscribeStatus = res.data
                if (this.subscribeStatus.wechat_attention) {
                    this.$notify({ type: 'success', message: '关注成功' })
                    this.showAttention = false
                    this.$emit('attention-success')
                }
            })
        }
    }
}
</script>

<style lang="less" scoped>
    .widget-popup {
        padding: 0px 16px;
        box-sizing: border-box;
        height: 60%;    //20210119调整高度,原先高度67%
    }
    .unsubscribe {
        margin-left: 5px;
        color: #2d8cf0;
    }
    .widget {
        .popup-title {
            display: flex;
            justify-content: space-between;
            align-items: center;

            p, a {
                font-size: 16px;
                line-height: 18px;
            }
        }
        .subscribe-qrcode {
            text-align: center;

            .van-image {
                max-width: 215px;

                img {
                    -webkit-touch-callout: none;
                }
            }
        }
        .add-tip {
            span {
                margin: 0px 8px;
                font-size: 16px;
            }
            i {
                width: 16px;
                height: 10px;
                background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAQCAYAAAAI0W+oAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA4RpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMDY3IDc5LjE1Nzc0NywgMjAxNS8wMy8zMC0yMzo0MDo0MiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDpiZWRmNGUyYi1iZDQzLTAyNDEtYTIxNS02YmU5ODFjYWQwNjUiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MkM4NTdDMzUzMTZEMTFFODk3NDM5NUFGQUE5QzEzRjQiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MkM4NTdDMzQzMTZEMTFFODk3NDM5NUFGQUE5QzEzRjQiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUgKFdpbmRvd3MpIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6NzFkZWNmZmUtYzQ2Yy05MDRlLTg4ZDktYTU5ZmM4OWZmOTA2IiBzdFJlZjpkb2N1bWVudElEPSJhZG9iZTpkb2NpZDpwaG90b3Nob3A6YTRhNmRkMDItMzBkOC0xMWU4LWI4MTktY2UyMWU1YTUwYmY5Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+i+/dBgAAARFJREFUeNpi/L9TgIEQYDz9Hkz/NxW0A1LdQKwKxH1A8RY82hygapWAuJORWIuAlgQDmcuAmA0q/BkozodDSyhULQuU/4aJgQiAxRIQuEekJWC1xFgUhMWSv0BcjkVtCBZL/gBxKRMRlizHYkk8MNh2YrFkOZolILVRQHyIiUxLlhLhE5DaSCBeDeIwUcESWPyx4rIEl0WkWrIciyVRyJYwoHkVnyVxQEuWkWDJKnTXM1FgCbbgisZmCbJFOC2BGkhMco8B4pW4UhbIIlsSLMGlFmTJCnz5BGRRD5GWMOBQS9ASmEUqRFrCgEVtLDGWwCyaBMRfgfgiEHvhsQQEJiCp9YQGI1GA8f///wz0AAABBgDlhWvGFq/PHwAAAABJRU5ErkJggg==)no-repeat;
                background-size: 16px 10px;
                display: inline-block;
            }
        }
        .subscribe-tip {
            color: #969799;
            font-size: 14px;
        }
    }
</style>
